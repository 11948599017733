import React from 'react'
import { ThemeProvider } from 'styled-components'

import { GlobalStyle } from './styles/global'
import { theme } from './styles/theme'
import Router from './Router'

import { Header, Content, Layout } from './components/layout'

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Layout>
        <Header />
        <Content>
          <Router />
        </Content>
      </Layout>
    </ThemeProvider>
  )
}

export default App
