import { WHITE, BLACK, BRAND_PRIMARY, BRAND_SECONDARY, TEXT, LINK, RED, GREEN, GRAY } from './colors'

export const theme = {
  colors: {
    // main
    white: WHITE,
    black: BLACK,
    red: RED,
    green: GREEN,
    gray: GRAY,

    // brand
    primary: BRAND_PRIMARY,
    secondary: BRAND_SECONDARY,

    // typography
    text: TEXT,
    link: LINK,
  },
  zindex: {
    dropdown: 1000,
    sticky: 1020,
    fixed: 1030,
    modalBackdrop: 1040,
    modal: 1050,
    tooltip: 1060,
  },
  shadow: {
    medium: '4px 4px 20px rgba(0, 0, 0, 0.1);',
    large: '6px 6px 40px rgba(0, 0, 0, 0.1);',
  },
  easing: {
    linear: 'linear',
    baseOut: 'cubic-bezier(0.7, 0.3, 0.1, 1)',
    baseIn: 'cubic-bezier(0.9, 0, 0.3, 0.7)',
    out: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
    in: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
    inOut: 'cubic-bezier(.46, 0, .03, .99)',
    outBack: 'cubic-bezier(0.12, 0.4, 0.29, 1.46)',
    inBack: 'cubic-bezier(0.71, -0.46, 0.88, 0.6)',
    inOutBack: 'cubic-bezier(0.71, -0.46, 0.29, 1.46)',
    outCirc: 'cubic-bezier(0.08, 0.82, 0.17, 1)',
    inCirc: 'cubic-bezier(0.6, 0.04, 0.98, 0.34)',
    inOutCirc: 'cubic-bezier(0.4, 0.15, 0.6, 0.85)',
    outQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
    inQuint: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
    inOutQuint: 'cubic-bezier(0.86, 0, 0.07, 1)',
  },
}
