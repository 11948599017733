import React from 'react'
import { Route, Routes } from 'react-router'

import WelcomePage from './pages/welcome'
import ResultsPage from './pages/results'

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<WelcomePage />} />
      <Route path="/:id" element={<ResultsPage />} />
    </Routes>
  )
}

export default Router
