import * as React from 'react'

import { TextProps } from '../types/Text.types'
import { Container } from '../styles/Text.sc'

/**
 * @description The Text component is used for single line or multiline text. The component renders a div element by default
 *
 * @component
 * @example
 * ```jsx
 * <Text as="div" size="xl" strong>Text</Text>
 * ```
 */
const Text = React.forwardRef<HTMLDivElement, TextProps>((props, ref) => {
  const { children, ...restProps } = props

  return (
    <Container ref={ref} {...restProps}>
      {children}
    </Container>
  )
})

Text.displayName = 'Text'
Text.defaultProps = {
  size: 16,
}

export { Text }
