import styled, { css } from 'styled-components'

import { HeadingProps, HeadingLevel } from '../types/Heading.types'

const getSizeStyles = (level: HeadingLevel) => {
  switch (level) {
    case 1:
      return css`
        font-size: 56px;
        line-height: 68px;
      `
    case 2:
      return css`
        font-size: 40px;
        line-height: 52px;
      `
    case 3:
      return css`
        font-size: 32px;
        line-height: 40px;
      `
    case 4:
      return css`
        font-size: 24px;
        line-height: 32px;
      `
    case 5:
      return css`
        font-size: 20px;
        line-height: 28px;
      `
    case 6:
      return css`
        font-size: 16px;
        line-height: 24px;
      `
  }
}

export const Container = styled.h1<HeadingProps>`
  font-weight: bold;

  ${({ level }) => getSizeStyles(level)}
`
