// BRAND
export const BRAND_PRIMARY = '#FF312C'
export const BRAND_SECONDARY = '#E0EBF0'

// COMMON
export const WHITE = '#FFF'
export const BLACK = '#000'
export const GRAY = '#1E1F33'
export const GREEN = '#15A250'
export const RED = '#DD4333'

// TYPOGRAPHY
export const LINK = '#518399'
export const TEXT = '#181B21'
