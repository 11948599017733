export const PERMISSIONS = [
  'geolocation',
  'notifications',
  'midi',
  'camera',
  'microphone',
  'background-sync',
  'persistent-storage',
  'accelerometer',
  'gyroscope',
  'magnetometer',
  'clipboard-read',
  'clipboard-write',
  'payment-handler',
] as PermissionName[]
