import styled, { css } from 'styled-components'

import { TextProps, TextSize } from '../types/Text.types'

const getSizeStyles = (size: TextSize = 12) => {
  switch (size) {
    case 12:
      return css`
        font-size: 12px;
        line-height: 16px;
      `
    case 14:
      return css`
        font-size: 14px;
        line-height: 24px;
      `
    case 16:
      return css`
        font-size: 16px;
        line-height: 24px;
      `
    case 18:
      return css`
        font-size: 18px;
        line-height: 28px;
      `
    case 20:
      return css`
        font-size: 20px;
        line-height: 32px;
      `
    case 22:
      return css`
        font-size: 22px;
        line-height: 36px;
      `
    case 24:
      return css`
        font-size: 24px;
        line-height: 40px;
      `
  }
}

const getStrongStyles = (strong?: boolean) =>
  strong &&
  css`
    font-weight: 600;
  `
const getNowrapStyles = (nowrap?: boolean) =>
  nowrap &&
  css`
    white-space: nowrap;
  `
const getUpperCaseStyles = (nowrap?: boolean) =>
  nowrap &&
  css`
    text-transform: uppercase;
  `
const getCapitalizeStyles = (capitalize?: boolean) =>
  capitalize &&
  css`
    text-transform: capitalize;
  `
const getLowerCaseStyles = (lowercase?: boolean) =>
  lowercase &&
  css`
    text-transform: lowercase;
  `
const getTruncateStyles = (truncate?: boolean) =>
  truncate &&
  css`
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `

export const Container = styled.div<TextProps>`
  ${({ size }) => getSizeStyles(size)}
  ${({ strong }) => getStrongStyles(strong)}
  ${({ nowrap }) => getNowrapStyles(nowrap)}
  ${({ uppercase }) => getUpperCaseStyles(uppercase)}
  ${({ capitalize }) => getCapitalizeStyles(capitalize)}
  ${({ lowercase }) => getLowerCaseStyles(lowercase)}
  ${({ truncate }) => getTruncateStyles(truncate)}
`
