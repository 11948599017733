import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { getBrowserInfo } from '../utils/browser'
import { create } from '../services/diagnostic'

import { Button } from '../components/ui/Button'
import { Heading } from '../components/ui/Typography'
import { Instruction } from '../components/ui/Instruction'

const Content = styled.div`
  margin-top: 8px;
  margin-bottom: 32px;
`

const Welcome = () => {
  const navigate = useNavigate()

  const handleClickProcess = React.useCallback(async () => {
    const info = await getBrowserInfo()
    const { data } = await create(info)

    navigate(`/${data._id}`)
  }, [])

  return (
    <div>
      <Heading level={3}>Диагностика браузера</Heading>

      <Instruction />

      <Button variant="action" size={48} block onClick={handleClickProcess}>
        Выполнить диагностику
      </Button>
    </div>
  )
}

export default Welcome
