import * as React from 'react'

import { LinkProps } from '../types/Link.types'
import { Container } from '../styles/Link.sc'

/**
 * @description The Link component is used for anchor links. This component renders a a element by default
 *
 * @component
 * @example
 * ```jsx
 * <Link href="#">Link</Link>
 * ```
 */
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { children, ...restProps } = props

  return (
    <Container ref={ref} {...restProps}>
      {children}
    </Container>
  )
})

Link.displayName = 'Link'
Link.defaultProps = {}

export { Link }
