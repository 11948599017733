import * as React from 'react'

import { HeaderProps } from '../types/Header.types'
import { Container, Logo } from '../styles/Header.sc'

const Header = React.forwardRef<HTMLDivElement, HeaderProps>((props, ref) => {
  const { ...restProps } = props

  return (
    <Container ref={ref} data-qa="Header" {...restProps}>
      <Logo />
    </Container>
  )
})

Header.displayName = 'Header'
Header.defaultProps = {}

export default Header
