import styled from 'styled-components'
import { lighten } from 'polished'

export const Container = styled.a`
  position: relative;
  color: ${({ theme }) => theme.colors.link};
  text-decoration: none;
  transition: color 0.3s ${({ theme }) => theme.easing.inOut};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => lighten(0.15, theme.colors.link)};
  }

  &[disabled] {
    color: ${({ theme }) => lighten(0.15, theme.colors.link)};
    cursor: not-allowed;
    pointer-events: none;
  }
`
