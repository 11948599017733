import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  //width: 100%;
  width: 500px;
  min-height: 100%;
  margin-top: 140px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`
