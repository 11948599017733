import axios from 'axios'
import qs from 'querystring'

import { runtimeConfig } from '../config'

const instance = axios.create({
  withCredentials: true,
  baseURL: runtimeConfig.API_PATH,
  timeout: 5000,
  paramsSerializer: (params) => {
    return qs.stringify(params)
  },
})

export default instance
