import type { AxiosResponse } from 'axios'

import http from '../utils/http'

export const findOne = async (id: string): Promise<AxiosResponse<IDiagnostics>> => {
  return http.get<IDiagnostics>(`/diagnostic/${id}`)
}

export const create = async (data: Partial<IDiagnostics>): Promise<AxiosResponse<IDiagnostics>> => {
  return http.post<IDiagnostics>('/diagnostic', data)
}
