import React from 'react'

import { Container } from '../styles/Logo.sc'

const Logo = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => {
  return (
    <Container ref={ref} viewBox="0 0 173 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.324615 22.1326L12.0468 2.6487C12.9517 1.14559 15.1826 1.14559 16.0864 2.6487L27.8085 22.1326C28.7227 23.6527 27.5974 25.5672 25.7887 25.5672H2.34443C0.535762 25.5672 -0.589582 23.6538 0.324615 22.1326Z"
        fill="#FF312C"
      />
      <path
        d="M38.4007 0.00187453C33.0779 -0.0851475 28.4547 2.86569 26.2331 7.1908C25.6437 8.33791 25.6936 9.69862 26.3595 10.805L34.8564 24.9275C35.165 25.4406 35.6964 25.7875 36.2997 25.8712C36.9111 25.9559 37.5364 26 38.1721 26C45.5229 26 51.486 20.2102 51.5173 13.0563C51.5498 5.9804 45.6644 0.119411 38.4007 0.00187453Z"
        fill="#FF312C"
      />
      <path
        d="M72.0241 0.433595H53.9107C52.7668 0.433595 52.1067 1.69372 52.7738 2.59897C54.9549 5.55999 56.2357 9.18553 56.2183 13.0789C56.2009 16.9181 54.934 20.4769 52.7958 23.3916C52.1264 24.3025 52.7807 25.5683 53.9304 25.5683H72.0241C73.6565 25.5683 74.9802 24.2788 74.9802 22.6886V3.31323C74.9802 1.7231 73.6565 0.433595 72.0241 0.433595Z"
        fill="#FF312C"
      />
      <path
        d="M150.656 16.0772C148.846 16.0772 147.686 14.6758 147.686 12.9354C147.686 11.195 148.799 9.77095 150.656 9.77095C152.512 9.77095 153.602 11.195 153.602 12.9354C153.602 14.6758 152.465 16.0772 150.656 16.0772ZM151.421 6.56131C149.704 6.56131 148.358 7.26201 147.639 8.16613V6.90036H144.275V22.7226H147.802V17.7725C148.567 18.654 149.959 19.2869 151.352 19.2869C155.018 19.2869 157.176 16.6649 157.176 12.8902C157.176 9.3415 154.971 6.56131 151.421 6.56131ZM169.241 6.85515L165.97 15.0149H165.505L162.28 6.85515H158.197L163.116 17.9759L163.765 19.0156L163.51 19.3773H159.496V22.7226H161.816C164.763 22.7226 166.619 21.4342 167.872 18.5636L173 6.85515H169.241ZM139.472 14.8793C138.219 14.8793 137.198 15.8738 137.198 17.0944C137.198 18.3375 138.219 19.3321 139.472 19.3321C140.748 19.3321 141.769 18.3375 141.769 17.0944C141.769 15.8738 140.748 14.8793 139.472 14.8793ZM130.655 6.85515L127.244 11.7148H126.873L123.508 6.85515H119.332V19.0156H122.812V12.3251H123.299L126.176 16.4615H127.847L130.771 12.3251H131.211V19.0156H134.692V6.85515H130.655ZM110.445 15.9868C108.635 15.9868 107.521 14.6306 107.521 12.9354C107.521 11.2628 108.612 9.90657 110.445 9.90657C112.301 9.90657 113.392 11.2628 113.392 12.9354C113.392 14.6306 112.255 15.9868 110.445 15.9868ZM110.445 6.56131C106.338 6.56131 103.878 9.43191 103.878 12.9354C103.878 16.4615 106.431 19.3095 110.445 19.3095C114.482 19.3095 117.011 16.4615 117.011 12.9354C117.011 9.43191 114.575 6.56131 110.445 6.56131ZM95.0612 9.95178L97.3119 15.7608H92.4161L94.6436 9.95178H95.0612ZM100.955 15.7608L97.4511 6.85515H92.1377L88.634 15.7608H86.6154V21.841H89.9798V19.0156H99.4466V21.841H102.834V15.7608H100.955Z"
        fill="#FF312C"
      />
    </Container>
  )
})

export default Logo
