import React from 'react'
import styled from 'styled-components'

export const List = styled.ol`
  padding: 0;
  list-style: none;
  margin-bottom: 32px;
`

export const ListItem = styled.li`
  margin: 20px 0 0;
  padding: 0;
  display: flex;
  line-height: 22px;
  counter-increment: muffins;

  &::before {
    content: counter(muffins);
    width: 20px;
    height: 20px;
    color: #ff312c;
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin-right: 20px;
    margin-top: 3px;
  }
`

const Instruction = () => {
  return (
    <List as="ol">
      <ListItem>Нажмите на кнопку "Выполнить диагностику". Будет произведено несколько проверок вашего браузера.</ListItem>
      <ListItem>Вы получите данные диагностики. Скопируйте урл страницы и передайте в поддержку.</ListItem>
    </List>
  )
}

export default Instruction
