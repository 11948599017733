import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useCopyToClipboard } from '@r1team/react-hooks'

import { findOne } from '../services/diagnostic'

import { Button } from '../components/ui/Button'
import { Heading } from '../components/ui/Typography'
import { Instruction } from '../components/ui/Instruction'

const HeadingStyled = styled(Heading)`
  margin-bottom: 30px;
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 100%;
  overflow: hidden;
`

const ContentStyled = styled.div`
  //margin: 40px auto;
`

const Wrapper = styled.div`
  background-color: #f6f7f8;
  border-radius: 20px;
  padding: 30px;
  margin-top: 32px;
`

const CookieBlock = styled.div`
  background-color: white;
  display: block;
  padding: 20px;
  overflow: hidden;
  word-break: break-word;
  border-radius: 8px;
  font-size: 16px;
  line-height: 26px;
`

const Label = styled.div`
  display: inline-block;
  width: 200px;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  margin-right: 20px;
  flex-shrink: 0;
  text-overflow: ellipsis;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    width: 180px;
  }
`

const Value = styled.div`
  display: inline-block;
  width: 280px;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;

  @media screen and (max-width: 767px) {
    width: calc(100% - 200px);
    word-break: break-all;
    overflow: hidden;
  }
`

export const Title = styled.div`
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  color: #8c8c8c;
  margin-bottom: 10px;
`

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

export const ListItem = styled.li`
  margin: 20px 0 0;
  padding: 0;
  display: flex;
  line-height: 22px;
`

export const ContentBlock = styled.div`
  margin: 30px 0;

  &:last-child {
    margin-bottom: 0;
  }
`

const Welcome = () => {
  const { id } = useParams()
  const [info, setInfo] = React.useState<IDiagnostics>()
  const [error, setError] = React.useState<Error>()
  const [value, copy] = useCopyToClipboard()

  const loadData = async () => {
    try {
      const { data } = await findOne(id as string)

      setInfo(data)
    } catch (err: any) {
      setError(err)
    }
  }

  const formatOutput = (value: string | boolean | undefined): string => {
    if (value) {
      if (value === true) {
        return 'да'
      }

      return value.toString()
    }

    return 'нет'
  }

  const mainInfoBlock = React.useMemo(() => {
    if (info) {
      return (
        <ContentBlock>
          <Title>Дополнительная информация</Title>
          <List>
            {info.ip ? (
              <ListItem>
                <Label>IP</Label>
                <Value>{info.ip || '-'}</Value>
              </ListItem>
            ) : null}
            {info.userAgent ? (
              <ListItem>
                <Label>userAgent</Label>
                <Value>{info.userAgent || '-'}</Value>
              </ListItem>
            ) : null}
          </List>
        </ContentBlock>
      )
    }

    return null
  }, [info])

  const resolutionInfoBlock = React.useMemo(() => {
    if (info && info.resolution) {
      return (
        <ContentBlock>
          <Title>Информация об экране:</Title>
          <List>
            <ListItem>
              <Label>Width</Label>
              <Value>{info.resolution.width || '-'}</Value>
            </ListItem>
            <ListItem>
              <Label>Height</Label>
              <Value>{info.resolution.height || '-'}</Value>
            </ListItem>
            <ListItem>
              <Label>ColorDepth</Label>
              <Value>{info.resolution.colorDepth || '-'}</Value>
            </ListItem>
          </List>
        </ContentBlock>
      )
    }

    return null
  }, [info])

  const supportsInfoBlock = React.useMemo(() => {
    if (info && info.supports && info.supports.length) {
      return (
        <ContentBlock>
          <Title>Поддержка технологий:</Title>
          <List>
            {info.supports.map((i) => (
              <ListItem key={i.title}>
                <Label>{i.title}</Label>
                <Value>{formatOutput(i.value)}</Value>
              </ListItem>
            ))}
          </List>
        </ContentBlock>
      )
    }

    return null
  }, [info])

  const osInfoBlock = React.useMemo(() => {
    if (info && info.os && info.os.length) {
      return (
        <ContentBlock>
          <Title>Информация об операционной системе:</Title>
          <List>
            {info.os.map((i) => (
              <ListItem key={i.title}>
                <Label>{i.title}</Label>
                <Value>{formatOutput(i.value)}</Value>
              </ListItem>
            ))}
          </List>
        </ContentBlock>
      )
    }

    return null
  }, [info])

  const browserInfoBlock = React.useMemo(() => {
    if (info && info.browser && info.browser.length) {
      return (
        <ContentBlock>
          <Title>Информация о браузере:</Title>
          <List>
            {info.browser.map((i) => (
              <ListItem key={i.title}>
                <Label>{i.title}</Label>
                <Value>{formatOutput(i.value)}</Value>
              </ListItem>
            ))}
          </List>
        </ContentBlock>
      )
    }

    return null
  }, [info])

  const permissionsInfoBlock = React.useMemo(() => {
    if (info && info.permissions && info.permissions.length) {
      return (
        <ContentBlock>
          <Title>Разрешения:</Title>
          <List>
            {info.permissions.map((i) => (
              <ListItem key={i.title}>
                <Label>{i.title}</Label>
                <Value>{formatOutput(i.value)}</Value>
              </ListItem>
            ))}
          </List>
        </ContentBlock>
      )
    }

    return null
  }, [info])

  const videoInfoBlock = React.useMemo(() => {
    if (info && info.video) {
      const { mpeg4, ogg, h264, webm, videoEl } = info.video

      return (
        <ContentBlock>
          <Title>Поддержка видео:</Title>
          <List>
            <ListItem>
              <Label>HTMLVideoElement</Label>
              <Value>{formatOutput(videoEl)}</Value>
            </ListItem>
            <ListItem>
              <Label>MP4</Label>
              <Value>{formatOutput(mpeg4)}</Value>
            </ListItem>
            <ListItem>
              <Label>Ogg</Label>
              <Value>{formatOutput(ogg)}</Value>
            </ListItem>
            <ListItem>
              <Label>H264</Label>
              <Value>{formatOutput(h264)}</Value>
            </ListItem>
            <ListItem>
              <Label>WebM</Label>
              <Value>{formatOutput(webm)}</Value>
            </ListItem>
          </List>
        </ContentBlock>
      )
    }

    return null
  }, [info])

  const cookiesBlock = React.useMemo(() => {
    if (info && info.cookies) {
      return (
        <ContentBlock>
          <Title>Сырые cookies:</Title>
          <CookieBlock>{info.cookies}</CookieBlock>
        </ContentBlock>
      )
    }

    return null
  }, [info])

  React.useEffect(() => {
    loadData()
  }, [id])

  // if (error) {
  //   return <Error status={String(error.status) || '404'} />
  // }

  return (
    <PageWrapper>
      <Heading level={3}>Диагностика браузера</Heading>

      <Instruction />

      <Button variant="action" size={48} block onClick={() => copy(location.href)}>
        Скопировать ссылку
      </Button>

      <Wrapper>
        <ContentStyled>
          <HeadingStyled level={4}>Данные расширеной диагностики</HeadingStyled>

          {resolutionInfoBlock}
          {osInfoBlock}
          {browserInfoBlock}
          {supportsInfoBlock}
          {permissionsInfoBlock}
          {videoInfoBlock}
          {mainInfoBlock}
          {cookiesBlock}
        </ContentStyled>
      </Wrapper>
    </PageWrapper>
  )
}

export default Welcome
