import { css } from 'styled-components'

export const fonts = css`
  @font-face {
    font-family: 'CoFo Sans';
    src: url('/fonts/coFoSans/CoFoSans-Regular.eot');
    src: local('CoFoSans Light'), local('CoFoSans-Regular'), url('/fonts/coFoSans/CoFoSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/coFoSans/CoFoSans-Regular.woff2') format('woff2'), url('/fonts/coFoSans/CoFoSans-Regular.woff') format('woff'),
      url('/fonts/coFoSans/CoFoSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'CoFo Sans';
    src: url('/fonts/coFoSans/CoFoSans-Bold.eot');
    src: local('CoFoSans Bold'), local('CoFoSans-Bold'), url('/fonts/coFoSans/CoFoSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/coFoSans/CoFoSans-Bold.woff2') format('woff2'), url('/fonts/coFoSans/CoFoSans-Bold.woff') format('woff'),
      url('/fonts/coFoSans/CoFoSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'CoFo Sans';
    src: url('/fonts/coFoSans/CoFoSans-Medium.eot');
    src: local('CoFoSans Medium'), local('CoFoSans-Medium'), url('/fonts/coFoSans/CoFoSans-Medium.eot?#iefix') format('embedded-opentype'),
      url('/fonts/coFoSans/CoFoSans-Medium.woff2') format('woff2'), url('/fonts/coFoSans/CoFoSans-Medium.woff') format('woff'),
      url('/fonts/coFoSans/CoFoSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
`
