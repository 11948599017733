import * as React from 'react'

import { HeadingProps } from '../types/Heading.types'
import { Container } from '../styles/Heading.sc'

/**
 * @description The Heading component is used for all headings.
 *
 * @component
 * @example
 * ```jsx
 * <Heading level={2}>
 *   Heading
 * </Heading>
 * ```
 */
const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => {
  const { as, level, children, ...restProps } = props
  const Component = as || `h${level}`

  return (
    <Container as={Component} ref={ref} level={level} {...restProps}>
      {children}
    </Container>
  )
})

Heading.displayName = 'Heading'
Heading.defaultProps = {
  level: 1,
}

export { Heading }
