import * as React from 'react'

import { ContentProps } from '../types/Content.types'
import { Container } from '../styles/Content.sc'

const Content = React.forwardRef<HTMLDivElement, ContentProps>((props, ref) => {
  const { children, ...restProps } = props

  return (
    <Container ref={ref} data-qa="Content" {...restProps}>
      {children}
    </Container>
  )
})

Content.displayName = 'Content'
Content.defaultProps = {}

export default Content
