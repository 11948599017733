import UAParser from 'ua-parser-js'

import { PERMISSIONS } from '../constants/browser'

export const isSupportWebP = (): boolean => {
  const elem = document.createElement('canvas')
  return !(!elem.getContext || !elem.getContext('2d')) && 0 === elem.toDataURL('image/webp').indexOf('data:image/webp')
}

export const isSupportSvg = (): boolean => {
  return !!document.createElementNS && !!document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect
}

export const getSupportVideo = (): IVideoInfo => {
  const e = document.createElement('video')
  let mpeg4 = false
  let h264 = false
  let ogg = false
  let webm = false

  return (
    e.canPlayType &&
      ((mpeg4 = '' !== e.canPlayType('video/mp4; codecs="mp4v.20.8"')),
      (h264 = '' !== (e.canPlayType('video/mp4; codecs="avc1.42E01E"') || e.canPlayType('video/mp4; codecs="avc1.42E01E, mp4a.40.2"'))),
      (ogg = '' !== e.canPlayType('video/ogg; codecs="theora"')),
      (webm = '' !== e.canPlayType('video/webm; codecs="vp8, vorbis"'))),
    {
      videoEl: !!e,
      mpeg4,
      h264,
      ogg,
      webm,
    }
  )
}

export const getPermissions = (): Promise<IOption[]> => {
  return Promise.all(PERMISSIONS.map((name) => navigator.permissions.query({ name }).then((status) => ({ title: name, value: status.state }))))
}

export const getBrowserInfo = async (): Promise<IBrowserDiagnostics> => {
  const parser = new UAParser()

  const browser = parser.getBrowser()
  const engine = parser.getEngine()
  const os = parser.getOS()
  const isMobile = ['mobile', 'table', 'wearable'].indexOf(parser.getDevice().type as string) >= 0

  let isTouch = false
  try {
    document.createEvent('TouchEvent')
    isTouch = true
  } catch (e) {}

  let permissions
  try {
    permissions = await getPermissions()
  } catch (e) {}

  let video
  try {
    video = getSupportVideo()
  } catch (e) {}

  return {
    resolution: {
      width: window.screen.width,
      height: window.screen.height,
      colorDepth: window.screen.colorDepth,
    },
    supports: [
      {
        title: 'isTouch',
        value: isTouch,
      },
      {
        title: 'isMobile',
        value: isMobile,
      },
      {
        title: 'postMessageSupport',
        value: !!window.postMessage,
      },
      {
        title: 'historySupport',
        value: !(!window.history || !window.history.pushState),
      },
      {
        title: 'WebPSupport',
        value: isSupportWebP(),
      },
      {
        title: 'localStorageSupport',
        value: !!window.localStorage,
      },
      {
        title: 'SVGSupport',
        value: isSupportSvg(),
      },
    ],
    os: [
      {
        title: 'name',
        value: os.name,
      },
      {
        title: 'version',
        value: os.version,
      },
    ],
    browser: [
      {
        title: 'baseVersion',
        value: browser && browser.version,
      },
      {
        title: 'engine',
        value: engine.name,
      },
      {
        title: 'engineVersion',
        value: (engine && engine.version) || '-',
      },
      {
        title: 'version',
        value: browser && browser.version,
      },
      {
        title: 'name',
        value: browser.name,
      },
      {
        title: 'browserName',
        value: browser && browser.version,
      },
      {
        title: 'appCodeName',
        value: window.navigator.appCodeName,
      },
      {
        title: 'appName',
        value: window.navigator.appName,
      },
      {
        title: 'appVersion',
        value: window.navigator.appVersion,
      },
      {
        title: 'language',
        value: window.navigator.language,
      },
      {
        title: 'platform',
        value: window.navigator.platform,
      },
      {
        title: 'product',
        value: window.navigator.product,
      },
      {
        title: 'productSub',
        value: window.navigator.productSub,
      },
      {
        title: 'vendor',
        value: window.navigator.vendor,
      },
    ],
    permissions: permissions || [],
    video,
    userAgent: window.navigator.userAgent,
  }
}
