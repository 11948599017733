import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  justify-content: center;
  width: 100%;
  //height: calc(100vh - 80px);
  min-height: 100vh;
  //margin-top: 80px;
  color: ${({ theme }) => theme.colors.text};
`
