import { createGlobalStyle } from 'styled-components'

import { fonts } from './fonts'
import { theme } from './theme'

type Theme = typeof theme
type Props = { theme: Theme }

export const GlobalStyle = createGlobalStyle`
  ${fonts}
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    background-color: ${({ theme }: Props) => theme.colors.white};
  }

  body {
    margin: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0;
    text-rendering: optimizeLegibility;
    background-color: ${({ theme }: Props) => theme.colors.white};
    color: ${({ theme }: Props) => theme.colors.text};
    font-family: CoFo Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    line-height: 20px;
  }

  #__next {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    line-height: 1;
  }

  menu, ol, p, small, ul {
    margin: 0;
    padding: 0;
  }

  button {
    font-family: inherit;
  }
`
